import React from 'react';
import { Button } from '@amzn/awsui-components-react';
import { useAppSelector } from 'src/stores/slices/hooks';
import { createIscCsvData, downloadCsvFile } from '@utils/csv';
import {
  Resource,
  ResourceAction,
  useHasResourcePermission,
} from '@hooks/useHasResourcePermission';

export function ExportCsvButton(): React.ReactElement {
  const parentDevicesChecked = useAppSelector(
    state => state.deviceState.parentDevices
  ).filter(device => device.checked);
  const childDevicesChecked = useAppSelector(
    state => state.deviceState.childDevices
  ).filter(device => device.checked);
  const subchildDevicesChecked = useAppSelector(
    state => state.deviceState.subchildDevices
  ).filter(device => device.checked);

  const isChecked = Boolean(
    parentDevicesChecked?.length ||
      childDevicesChecked?.length ||
      subchildDevicesChecked?.length
  );
  const { hasPermission: hasDeviceReadPermission } = useHasResourcePermission(
    Resource.Device,
    ResourceAction.Read
  );
  const { hasPermission: hasDeviceWritePermission } = useHasResourcePermission(
    Resource.Device,
    ResourceAction.Write
  );

  function exportCvsFile(): void {
    const csvData = createIscCsvData(
      parentDevicesChecked,
      childDevicesChecked,
      subchildDevicesChecked
    );
    downloadCsvFile('ISC_Devices', csvData);
  }

  return (
    <Button
      className={'ninetyWidth'}
      disabled={
        !(hasDeviceReadPermission || hasDeviceWritePermission) || !isChecked
      }
      disabledReason={
        !(hasDeviceReadPermission || hasDeviceWritePermission)
          ? 'Missing Device Read or Write permissions'
          : !isChecked
          ? 'Please select devices to export'
          : undefined
      }
      onClick={(): void => {
        exportCvsFile();
      }}
      iconName={'download'}>
      Export CSV
    </Button>
  );
}
