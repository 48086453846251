import { Entity } from '@features/milestones/types/api-types';
import { duplicateName } from '@utils/helpers';
import {
  getDuplicateNameErrorMessage,
  getInvalidNameErrorMessage,
  getNameLengthErrorMessage,
} from '@utils/validations/constants/ErrorMessageConstant';
import { NL } from '@utils/validations/constants/Constant';

const cameraDeviceType = 'Camera';

export const getCameraNameFieldErrors = (
  allNodeNames: string[],
  node: Entity,
  newName: string
): string[] => {
  const errorMessages: string[] = [];
  if (node.name !== newName && duplicateName(allNodeNames, newName)) {
    errorMessages.push(getDuplicateNameErrorMessage(cameraDeviceType));
  }
  if (newName.length > 96) {
    errorMessages.push(getNameLengthErrorMessage(cameraDeviceType));
  }
  const siteCode: string | undefined = node?.siteCode;
  const regex = new RegExp(
    `^(?:uc_|dcmsn_)?${siteCode}-(INT|EXT)[1-6](.*)${NL}$`
  );
  if (!regex.test(newName)) {
    errorMessages.push(
      getInvalidNameErrorMessage(
        cameraDeviceType,
        '[uc_|dcmsn_]{SITECODE}-(INT or EXT)[1-6](.*)(NL)?'
      )
    );
  }
  return errorMessages;
};
