import React, { PropsWithChildren } from 'react';
import {
  Resource,
  ResourceAction,
  useHasResourcePermission,
} from '@hooks/useHasResourcePermission';

interface Props {
  action: ResourceAction;
  inverse?: boolean;
  resourceType: Resource;
}

export function PermissionGuard({
  resourceType,
  action,
  inverse,
  children,
}: PropsWithChildren<Props>) {
  const { hasPermission } = useHasResourcePermission(resourceType, action);

  if ((inverse && hasPermission) || (!inverse && !hasPermission)) {
    return <></>;
  }

  return <>{children}</>;
}
