import { envVariables } from 'src/resources/envVariables';

export const useExternalModuleNavigate = () => {
  const stage = envVariables.stage;

  const navigateToBetterGuard = (): void => {
    window.location.href =
      stage === 'prod'
        ? 'https://silencio.acs.amazon.dev/'
        : `https://${stage}.silencio.acs.amazon.dev/`;
  };

  return {
    navigateToBetterGuard,
  };
};
