/* eslint-disable react/react-in-jsx-scope */
import DeviceLinkFileUpload from '@features/isc/DeviceLinkFileUpload';
import React from 'react';

import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
import DeviceRenameTest from 'src/features/isc/DeviceRenameTest';
import DeviceRenamePage from 'src/pages/isc/DeviceRenamePage';
import IscLayout from 'src/pages/isc/layout/IscLayout';
import RecordersPage from 'src/pages/milestones/RecordersPage';
import SitesPage from 'src/pages/milestones/SitesPage';
import TreePage from 'src/pages/milestones/TreePage';
import MilestonesLayout from 'src/pages/milestones/layout/MilestonesLayout';
import ProtectedRoute from '@components/ProtectedRoute';
import { AuthCallback } from '@features/auth/routes/AuthCallbackRoute';

export enum AppPaths {
  AuthCallback = '/auth/callback',
  Devices = '/devices',
  Milestones = '/milestones',
  RecordersView = '/milestones/recorders',
  Sites = 'sites',
  SitesView = '/milestones/sites',
  Test = '/test',
  TreeRecordersView = '/milestones/tree/recorders',
  TreeSingleView = '/milestones/tree',
}

const routes: RouteObject[] = [
  {
    element: (
      <ProtectedRoute>
        <IscLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={AppPaths.Devices} />,
      },
      {
        path: AppPaths.Devices,
        element: <DeviceRenamePage />,
      },
      {
        path: AppPaths.Test,
        element: <DeviceRenameTest />,
      },
      {
        path: AppPaths.AuthCallback,
        element: <AuthCallback />,
      },
    ],
  },
];

// Milestones are now available in all stages
routes.push({
  path: AppPaths.Milestones,
  element: (
    <ProtectedRoute>
      <MilestonesLayout />
      <DeviceLinkFileUpload />
    </ProtectedRoute>
  ),
  children: [
    {
      path: AppPaths.Milestones,
      element: <Navigate replace to={AppPaths.RecordersView} />,
    },
    {
      path: AppPaths.SitesView,
      element: <SitesPage />,
    },
    {
      path: AppPaths.RecordersView,
      element: <RecordersPage />,
    },
    {
      path: AppPaths.TreeSingleView, // /milestones/tree?site=site1 or /milestones/tree?recorder=recorder1
      element: <TreePage />, // using path params will allow us to save tree or cache, if needed in the future
    },
    {
      path: AppPaths.TreeRecordersView, // /milestones/tree/recorders (no search params)
      element: <TreePage />,
    },
  ],
});

export const router = createBrowserRouter(routes);
