import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import {
  RenameProps,
  useRenameMilestonesNode,
} from '@features/milestones/hooks/useRenameMilestonesNode';
import { ActionMenuIds, AriaLabels } from '@features/milestones/tree/constants';
import { useAppDispatch, useAppSelector } from '@stores/slices/hooks';
import { selectCheckNodes } from '@stores/slices/milestones/tree/treeSlice';
import { toggleShowMilestonesCsvUpload } from '@stores/slices/userSlice';
import { createMilestonesCsvData, downloadCsvFile } from '@utils/csv';
import { isEqualNodesArrays } from '@utils/nodeInfo';
import { log } from '@utils/helpers';
import {
  Resource,
  ResourceAction,
  useHasResourcePermission,
} from '@hooks/useHasResourcePermission';

interface DisableOptionsEntry {
  disabled: boolean;
  disabledReason: string;
}

interface DisabledOptions {
  addDcmsn: DisableOptionsEntry;
  addUc: DisableOptionsEntry;
  exportCsv: DisableOptionsEntry;
  removeAllTags: DisableOptionsEntry;
  removeDcmsn: DisableOptionsEntry;
  removeUc: DisableOptionsEntry;
  uploadCsv: DisableOptionsEntry;
}

export default function ActionMenu(): ReactElement {
  const dispatch = useAppDispatch();
  const checkedNodes = useAppSelector(selectCheckNodes);
  const [versionedCheckedNodes, setVersionedCheckedNodes] =
    useState(checkedNodes);

  useEffect(() => {
    if (!isEqualNodesArrays(checkedNodes, versionedCheckedNodes)) {
      setVersionedCheckedNodes(checkedNodes);
    }
  }, [checkedNodes, versionedCheckedNodes]);
  const { hasPermission: hasDeviceReadPermission } = useHasResourcePermission(
    Resource.Device,
    ResourceAction.Read
  );
  const { hasPermission: hasDeviceWritePermission } = useHasResourcePermission(
    Resource.Device,
    ResourceAction.Write
  );

  const disabledOptionsMap: DisabledOptions = useMemo(() => {
    log('*** disabledOptionsMap useMemo() has been triggered');
    const hasCheckedNodes = versionedCheckedNodes.length > 0;
    const ucNodes = versionedCheckedNodes.filter(node =>
      node.name.match(/^uc_/)
    );
    const dcmsnNodes = versionedCheckedNodes.filter(node =>
      node.name.match(/^dcmsn_/)
    );

    return {
      addUc: {
        disabled:
          !hasDeviceWritePermission ||
          !hasCheckedNodes ||
          ucNodes.length > 0 ||
          dcmsnNodes.length > 0, // disable if any have 'uc_...' (or 'dcmsn_')
        disabledReason: !hasDeviceWritePermission
          ? 'Missing Device Write permissions'
          : 'None selected, or selection includes uc_ names',
      },
      removeUc: {
        disabled:
          !hasDeviceWritePermission ||
          !hasCheckedNodes ||
          ucNodes.length !== versionedCheckedNodes.length || // disable if not all have 'uc_...'
          dcmsnNodes.length > 0,
        disabledReason: !hasDeviceWritePermission
          ? 'Missing Device Write permissions'
          : 'None selected, or selection includes some non-uc_ names',
      },
      addDcmsn: {
        disabled:
          !hasDeviceWritePermission ||
          !hasCheckedNodes ||
          dcmsnNodes.length > 0 ||
          ucNodes.length > 0, // disable if any have 'dcmsn_...'
        disabledReason: !hasDeviceWritePermission
          ? 'Missing Device Write permissions'
          : 'None selected, or selection includes dcmsn_ names',
      },
      removeDcmsn: {
        disabled:
          !hasDeviceWritePermission ||
          !hasCheckedNodes ||
          dcmsnNodes.length !== versionedCheckedNodes.length || // disable if not all have 'dcmsn_...'
          ucNodes.length > 0,
        disabledReason: !hasDeviceWritePermission
          ? 'Missing Device Write permissions'
          : 'None selected, or selection includes some non-dcmsn_ names',
      },
      exportCsv: {
        disabled:
          !(hasDeviceReadPermission || hasDeviceWritePermission) ||
          !hasCheckedNodes,
        disabledReason: 'No items selected to export',
      },
      uploadCsv: {
        disabled: !hasDeviceWritePermission,
        disabledReason: 'Missing Device Write permissions',
      },
      removeAllTags: {
        disabled: true,
        disabledReason: 'Not implemented',
      },
    };
  }, [versionedCheckedNodes]);

  // TODO: this is for clearing cache and refetching (only if rename is successful)
  const renameProps: RenameProps = {
    onRename: (oldId: string): void => {
      log(` - (empty) onRename callback called with oldId='${oldId}'`);
    },
  };
  const { renameCallback } = useRenameMilestonesNode(renameProps);

  function handleItemClick(id: string): void {
    switch (id) {
      case ActionMenuIds.AddUc:
        handleRenameEvent((name: string) => `uc_${name}`);
        break;
      case ActionMenuIds.RemoveUc:
        handleRenameEvent((name: string) => name.replace(/^uc_/, ''));
        break;
      case ActionMenuIds.AddDcmsn:
        handleRenameEvent((name: string) => `dcmsn_${name}`);
        break;
      case ActionMenuIds.RemoveDcmsn:
        handleRenameEvent((name: string) => name.replace(/^dcmsn_/, ''));
        break;
      case ActionMenuIds.ExportCsv:
        handleExportCsvEvent();
        break;
      case ActionMenuIds.UploadCsv:
        handleUploadCsvEvent();
        break;
      default:
        log(`We do not implement ${id} feature yet`);
        break;
    }
  }

  function handleRenameEvent(makeNewName: (name: string) => string): void {
    log('** handleRenameEvent()');
    checkedNodes.forEach(node => {
      const newName = makeNewName(node.name);
      log(` - ${node.name} => ${newName}`);
      renameCallback(node, newName);
    });
  }

  function handleExportCsvEvent(): void {
    const csvData = createMilestonesCsvData(checkedNodes);
    downloadCsvFile('Milestone_Devices', csvData);
  }

  function handleUploadCsvEvent(): void {
    dispatch(toggleShowMilestonesCsvUpload());
  }

  return (
    <ButtonDropdown
      onItemClick={(event): void => handleItemClick(event.detail.id)}
      ariaLabel={AriaLabels.ActionMenu}
      items={[
        {
          text: 'Add uc_',
          id: ActionMenuIds.AddUc,
          ...disabledOptionsMap.addUc,
          iconName: 'add-plus',
        },
        {
          text: 'Remove uc_',
          id: ActionMenuIds.RemoveUc,
          ...disabledOptionsMap.removeUc,
          iconName: 'remove',
        },
        {
          text: 'Add dcmsn_',
          id: ActionMenuIds.AddDcmsn,
          ...disabledOptionsMap.addDcmsn,
          iconName: 'add-plus',
        },
        {
          text: 'Remove dcmsn_',
          id: ActionMenuIds.RemoveDcmsn,
          ...disabledOptionsMap.removeDcmsn,
          iconName: 'remove',
        },
        {
          text: 'Upload CSV',
          id: ActionMenuIds.UploadCsv,
          ...disabledOptionsMap.uploadCsv,
          iconName: 'upload',
        },
        {
          text: 'Export CSV',
          id: ActionMenuIds.ExportCsv,
          ...disabledOptionsMap.exportCsv,
          iconName: 'download',
        },
        {
          text: 'Remove all tags',
          id: ActionMenuIds.RemoveAll,
          ...disabledOptionsMap.removeAllTags,
          iconName: 'remove',
        },
      ]}
      variant="primary">
      Action Menu
    </ButtonDropdown>
  );
}
