import React from 'react';
import { Link } from '@amzn/awsui-components-react';
import { CONSTANTS } from 'src/resources/constants';
import IsItDown from 'src/components/IsItDown';
import { Spinner } from '@amzn/awsui-components-react/uxdg';
import { useUserSites } from '@hooks/useUserSites';

export function SplashInfo(): React.ReactElement {
  const { data: sites, isError, isForbidden, isLoading } = useUserSites();

  if (isLoading) {
    return (
      <>
        <IsItDown key={'19636'} />
        <div>
          <Spinner size={'large'} />
        </div>
        <h3>Logging you in and checking for site access...</h3>
      </>
    );
  }

  if (isError) {
    return (
      <>
        <IsItDown />
        <span style={{ color: 'red' }}>
          <h3>Error getting your assigned sites</h3>
          <h3>Please check your connection.</h3>
        </span>
      </>
    );
  }

  if (isForbidden) {
    return (
      <>
        <IsItDown />
        <span style={{ color: 'red' }}>
          <h3>It appears you are missing Site Read permissions.</h3>
          <Link href={CONSTANTS.HELP_LINK} external target={'_blank'}>
            Need Assistance?
          </Link>
        </span>
      </>
    );
  }

  if (!sites || sites.length === 0) {
    return (
      <>
        <IsItDown />
        <span style={{ color: 'red' }}>
          <h3>It appears you are not authorized to use this system.</h3>
          <Link href={CONSTANTS.HELP_LINK} external target={'_blank'}>
            Need Assistance?
          </Link>
        </span>
      </>
    );
  }

  // Sites are loaded, Return empty fragment this should close this modal too
  return <></>;
}
