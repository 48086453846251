import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Hub } from 'aws-amplify';
import { AppPaths } from 'src/routes/Routes';

export const AuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    Hub.listen('auth', capsule => {
      if (capsule.payload.event === 'customOAuthState') {
        navigate(capsule.payload.data);
      }
    });
  }, [location, navigate]);

  return <Navigate replace to={AppPaths.Devices} />;
};
