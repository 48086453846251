import React from 'react';
import { Density, applyDensity } from '@amzn/awsui-global-styles';
import { SplashInfo } from './SplashInfo';
import { envVariables } from 'src/resources/envVariables';
import { AppLayout, Box, Modal } from '@amzn/awsui-components-react';

applyDensity(Density.Compact);

export default function Splash(): React.ReactElement {
  let envNote = <></>;
  if (envVariables.stage.toLowerCase() !== 'prod') {
    envNote = (
      <Box>
        <b>Environment: {envVariables.stage.toLowerCase()} </b>
      </Box>
    );
  }

  return (
    <AppLayout
      content={
        <Modal
          id={'splashModal'}
          visible={true}
          size="medium"
          header="Authenticating"
          footer={envNote}>
          <Box textAlign={'center'}>
            <SplashInfo />
            <p />
          </Box>
        </Modal>
      }
      toolsHide={true}
      navigationHide={true}
    />
  );
}
