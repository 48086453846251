import {
  BetterGuardClient,
  ServiceName,
  jwtTokenFromLocalStorage,
  setBetterGuardGlobalLogger,
} from '@amzn/acs-better-guard-common';
import { envVariables } from 'src/resources/envVariables';
import { useAppSelector } from '@stores/slices/hooks';
import { selectUsername } from '@stores/slices/userSlice';

const betterGuardClient = new BetterGuardClient(
  ServiceName.DeviceRename,
  jwtTokenFromLocalStorage(envVariables.userPoolClientId)
);

// eslint-disable-next-line no-console
setBetterGuardGlobalLogger(console.log);

export enum Resource {
  Device = 'Device',
  DeviceLink = 'DeviceLink',
  Site = 'Site',
}

export enum ResourceAction {
  Read = 'Read',
  Write = 'Write',
}

export function useHasResourcePermission(
  resourceType: Resource,
  action: ResourceAction
) {
  const username = useAppSelector(selectUsername);

  if (!username) {
    //Login process not finished, no permissions to do anything
    return { hasPermission: false };
  }

  return { hasPermission: betterGuardClient.canAccess(resourceType, action) };
}
