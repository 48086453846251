import { Entity } from '@features/milestones/types/api-types';
import { duplicateName } from '@utils/helpers';
import {
  getDuplicateNameErrorMessage,
  getInvalidNameErrorMessage,
  getNameLengthErrorMessage,
} from '@utils/validations/constants/ErrorMessageConstant';
import { NL } from '@utils/validations/constants/Constant';

const encoderCameraDeviceType = 'Encoder camera';

export const getEncoderCameraNameFieldErrors = (
  allNodeNames: string[],
  node: Entity,
  newName: string
): string[] => {
  const errorMessages: string[] = [];
  const cameraChannelNumber = node.name.substring(
    node.name.indexOf(' - Camera '),
    node.name.length
  );
  if (node.name !== newName && duplicateName(allNodeNames, newName)) {
    errorMessages.push(getDuplicateNameErrorMessage(encoderCameraDeviceType));
  }
  if (newName.length > 96) {
    errorMessages.push(getNameLengthErrorMessage(encoderCameraDeviceType));
  }
  const siteCode: string | undefined = node?.siteCode;
  const ip = '\\s+\\(\\d+\\.\\d+\\.\\d+\\.\\d+\\)';
  const cameraSuffix = '(?:\\s-\\sCamera\\s*(\\d+))';
  const regex = new RegExp(
    `^(?:UC_|DCMSN_|uc_|dcmsn_)?${siteCode}-(INT|EXT)[1-6](.*)${ip}${cameraSuffix}${NL}$`
  );
  if (!regex.test(newName)) {
    errorMessages.push(
      getInvalidNameErrorMessage(
        encoderCameraDeviceType,
        `[uc_|dcmsn_]{SITECODE}-(INT or EXT)[1-6](.*)(IP)${cameraChannelNumber} (NL)?`
      )
    );
  }
  return errorMessages;
};
