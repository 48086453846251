/* eslint-disable no-console */
import {
  RenameProps,
  useRenameMilestonesNode,
} from '@features/milestones/hooks/useRenameMilestonesNode';
import { log } from '@utils/helpers';
import React, { ReactElement } from 'react';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import { useAppDispatch, useAppSelector } from '@stores/slices/hooks';
import {
  selectCheckNodes,
  setEditingNode,
} from '@stores/slices/milestones/tree/treeSlice';
import { UINode } from 'src/features/milestones/types/ui-types';
import {
  Resource,
  ResourceAction,
  useHasResourcePermission,
} from '@hooks/useHasResourcePermission';

enum ItemsIds {
  AddDcmsn = 'add_dcmsn',
  AddUc = 'add_uc',
  RemoveDcmsn = 'remove_dcmsn',
  RemoveUc = 'remove_uc',
  Rename = 'rename',
}

export interface InlineMenuProps {
  node: UINode;
}

const InlineMenu = ({ node }: InlineMenuProps): ReactElement => {
  const dispatch = useAppDispatch();
  const checkedNodes = useAppSelector(selectCheckNodes) || [];

  const renameProps: RenameProps = {
    onRename: (oldId: string): void => {
      log(`Successfully renamed device via inline menu with Id='${oldId}'`);
    },
  };
  const { renameCallback } = useRenameMilestonesNode(renameProps);

  function handleRenameEvent(makeNewName: (name: string) => string): void {
    log('** handleRenameEvent()');
    const newName = makeNewName(node.name);
    log(` - ${node.name} => ${newName}`);
    renameCallback(node, newName);
  }
  const { hasPermission: hasDeviceWritePermission } = useHasResourcePermission(
    Resource.Device,
    ResourceAction.Write
  );

  function handleItemClick(id: string): void {
    switch (id) {
      case ItemsIds.Rename:
        dispatch(setEditingNode(node));
        break;
      case ItemsIds.AddUc:
        handleRenameEvent((name: string) => `uc_${name}`);
        break;
      case ItemsIds.RemoveUc:
        handleRenameEvent((name: string) => name.replace(/^uc_/, ''));
        break;
      case ItemsIds.AddDcmsn:
        handleRenameEvent((name: string) => `dcmsn_${name}`);
        break;
      case ItemsIds.RemoveDcmsn:
        handleRenameEvent((name: string) => name.replace(/^dcmsn_/, ''));
        break;
      default:
        alert(`We do not implement ${id} feature yet`);
        break;
    }
  }

  return (
    <ButtonDropdown
      expandToViewport={true}
      onItemClick={(event): void => handleItemClick(event.detail.id)}
      items={[
        {
          id: ItemsIds.Rename,
          text: 'Rename',
          disabled: !hasDeviceWritePermission || checkedNodes.length > 1,
          disabledReason: !hasDeviceWritePermission
            ? 'Missing Device Write permissions'
            : 'Multiple items are selected',
          iconName: 'edit',
        },
        {
          id: ItemsIds.AddUc,
          text: 'Add uc_',
          disabled:
            !hasDeviceWritePermission ||
            node.name.startsWith('uc_') ||
            node.name.startsWith('dcmsn_'),
          disabledReason: !hasDeviceWritePermission
            ? 'Missing Device Write permissions'
            : 'Name already has a prefix',
          iconName: 'add-plus',
        },
        {
          id: ItemsIds.RemoveUc,
          text: 'Remove uc_',
          disabled: !hasDeviceWritePermission || !node.name.startsWith('uc_'),
          disabledReason: !hasDeviceWritePermission
            ? 'Missing Device Write permissions'
            : 'Name does not start with uc_ prefix',
          iconName: 'remove',
        },
        {
          id: ItemsIds.AddDcmsn,
          text: 'Add dcmsn_',
          disabled:
            !hasDeviceWritePermission ||
            node.name.startsWith('uc_') ||
            node.name.startsWith('dcmsn_'),
          disabledReason: !hasDeviceWritePermission
            ? 'Missing Device Write permissions'
            : 'Name already has a prefix',
          iconName: 'add-plus',
        },
        {
          id: ItemsIds.RemoveDcmsn,
          text: 'Remove dcmsn_',
          disabled:
            !hasDeviceWritePermission || !node.name.startsWith('dcmsn_'),
          disabledReason: !hasDeviceWritePermission
            ? 'Missing Device Write permissions'
            : 'Name does not start with dcmsn_ prefix',
          iconName: 'remove',
        },
      ]}
      ariaLabel={`${node.name} menu`}
      variant="inline-icon"
    />
  );
};

export default InlineMenu;
