/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React from 'react';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { log } from 'src/utils/helpers';
import {
  selectAuthToken,
  selectUsername,
  setAuthToken,
  setUsername,
} from 'src/stores/slices/userSlice';
import { Auth } from 'aws-amplify';
import {
  selectDarkMode,
  selectVisualRefresh,
  setDarkMode,
} from 'src/stores/slices/userPrefsSlice';
import { envVariables } from 'src/resources/envVariables';
import Splash from './Splash';
import SelectSite from './SelectSite';
const ProtectedRoute = ({ children }: any) => {
  const stage = envVariables.stage;
  const darkMode = useAppSelector(selectDarkMode);
  const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
  const visualRefresh = useAppSelector(selectVisualRefresh);
  const username = useAppSelector(selectUsername);
  const authToken = useAppSelector(selectAuthToken);
  const dispatch = useAppDispatch();
  // window.addEventListener('resize', handleResize);

  useEffect(() => {
    switch (stage.toLowerCase()) {
      case 'prod':
        document.title = 'Device Rename';
        break;
      default:
        document.title = `Device Rename ${stage}`;
        break;
    }
  });

  useEffect(() => {
    if (darkMode) {
      document.body.classList.remove('awsui-polaris-light-mode');
      document.body.classList.add('awsui-polaris-dark-mode');
    } else if (!darkMode) {
      document.body.classList.remove('awsui-polaris-dark-mode');
      document.body.classList.add('awsui-polaris-light-mode');
    }
  }, [darkMode]);

  useEffect(() => {
    const toggleDarkMode = (e: MediaQueryListEvent) => {
      dispatch(setDarkMode(e.matches));
    };

    matchMedia.addEventListener('change', toggleDarkMode);

    return () => matchMedia.removeEventListener('change', toggleDarkMode);
  }, [dispatch, matchMedia]);

  useEffect(() => {
    if (visualRefresh) {
      document.body.classList.add('awsui-visual-refresh');
    } else {
      document.body.classList.remove('awsui-visual-refresh');
    }

    return (): void => {
      visualRefresh;
    };
  }, [visualRefresh]);

  const setUsernameAndToken = useCallback(
    (cognitoUserData: any) => {
      dispatch(
        setAuthToken(
          cognitoUserData.getSignInUserSession().getIdToken().getJwtToken()
        )
      );
      dispatch(setUsername(cognitoUserData.username.split('_')[1]));
    },
    [dispatch]
  );

  useEffect(() => {
    if (username && authToken) {
      return;
    }
    Auth.currentAuthenticatedUser()
      .then(cognitoUserData => {
        //setUserData(cognitoUserData);
        //setAuthLoaded(true);
        // const username = cognitoUserData.username.split('_')[1];
        //Auth.currentAuthenticatedUser().then((e) => {
        setUsernameAndToken(cognitoUserData);
        //});
      })
      .catch(err => {
        log('No user signed in, performing federatedSignIn', false, {
          error: err,
        });
        //setAuthLoaded(true);
        Auth.federatedSignIn({ customProvider: 'AmazonFederate' })
          .then(() => {
            Auth.currentAuthenticatedUser()
              .then(cognitoUserData => {
                // log(`new token: ${JSON.stringify(cognitoUserData)}`);
                setUsernameAndToken(cognitoUserData);
              })
              .catch(err => {
                log(
                  `Federated sign in currentAuthenticatedUser failed with ${err}`,
                  true
                );
              });
          })
          .catch(err => {
            log('Federated sign in failed with ', true, err);
          });
      });
  }, [authToken, setUsernameAndToken, username]);

  //we will persist this to browser local storage, and keep a TTL of 1 hour or so
  //it would fix the problem of getting sites on every reloading
  //please note that Redux store is resetting on reloading, so allowedSites will be empty on every reload
  const allowedSites = useAppSelector(state => state.deviceState.allowedSites);

  if (!allowedSites.length) {
    return <Splash />;
  }

  return (
    <>
      <SelectSite />
      {children}
    </>
  );
};

export default ProtectedRoute;
